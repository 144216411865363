<template>
  <div>
    <form @submit.prevent="saveService(repairService)">
      <div class="row">
        <div class="col-xl-3 col-4 my-3">
          <label for="serviceInput"> Service</label>
          <input
            v-model="service_reference"
            @input="onServiceChangeSelected(service_reference)"
            list="serviceList"
            class="form-control"
            id="serviceInput"
          />
          <datalist id="serviceList">
            <option
              v-for="(service, index) in filteredServices"
              :key="index"
              :value="service.reference"
            >
              {{ service.fullName }}
            </option>
          </datalist>
        </div>

        <div class="col-xl-5 col my-3">
          <label for=""> Service </label>
          <input
            :value="selectedService.fullName"
            disabled
            class="form-control"
          />
        </div>

        <div class="col-xl col-2 my-3">
          <label for="">Prix TTC </label>
          <input
            required
            v-model="repairService.priceTTC"
            class="form-control"
          />
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="col-auto">
          <label for=""></label>
          <button class="btn btn-success shadow" type="submit">
            <i class="bi bi-plus-circle"></i> Insérer
          </button>
        </div>

        <div class="col-auto">
          <label for=""></label>
          <button
            type="button"
            class="btn btn-secondary d-flex align-items-center"
            @click="refresh()"
          >
            <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
            <span> Actualiser </span>
          </button>
        </div>
      </div>
    </form>
    <hr />
    <!-- <form @submit.prevent="saveItem(repairItem)">
      <div class="row align-items-end">
        <div class="col-xl-2 col-4 my-3">
          <label for="">Catégorie</label>
          <select v-model="section_reference" class="form-select">
            <option value="" selected>Choisir une catégorie</option>
            <option
              v-for="(section, index) in sections"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
        </div>

        <div class="col-xl-2 col-3 my-3">
          <label for="itemCodeInput"> Code </label>
          <input
            v-model="itemCode"
            @input="onItemCodeChangeSelected(itemCode)"
            list="itemCodeList"
            class="form-control"
            id="itemCodeInput"
          />
          <datalist id="itemCodeList">
            <option
              v-for="(item, index) in filteredItems"
              :key="index"
              :value="item.code"
            >
              {{ item.code }}
            </option>
          </datalist>
        </div>

        <div class="col-xl-3 col-5 my-3">
          <label for="itemInput">Produit</label>
          <input
            v-model="item_reference"
            @input="onItemChangeSelected(item_reference)"
            list="itemList"
            class="form-control"
            id="itemInput"
          />
          <datalist id="itemList">
            <option
              v-for="(item, index) in filteredItems"
              :key="index"
              :value="item.reference"
            >
              {{ item.fullName }}
            </option>
          </datalist>
        </div>

        <div class="col-xl-5 col-4 my-3">
          <label for="">Produit</label>
          <input :value="selectedItem.fullName" disabled class="form-control" />
        </div>

        <div class="col-xl-2 col-2 my-3">
          <label for=""> TVA </label>
          <input
            :value="selectedItem.tva + ' %'"
            disabled
            class="form-control"
          />
        </div>

        <div class="col-xl-2 col-3 my-3">
          <label for=""> Prix d'achat TTC </label>
          <input
            v-if="Object.keys(filteredStocks).length == 1"
            :value="filteredStocks[0].purchasePriceTTC + ' DIRHAMS'"
            disabled
            class="form-control"
          />
          <input v-else :value="' '" disabled class="form-control" />
        </div>

        <div class="col-xl-3 col-3 my-3">
          <label for=""> Stock <span class="text-danger">*</span> </label>
          <select
            v-if="Object.keys(filteredStocks).length > 1"
            v-model="repairItem.stock_reference"
            class="form-select"
          >
            <option
              v-for="(stock, index) in filteredStocks"
              :key="index"
              :value="stock.reference"
            >
              {{ stock.fullName }} Quantité : {{ stock.quantity }}
              {{ stock.measuring }}
            </option>
          </select>
          <input
            v-else-if="Object.keys(filteredStocks).length == 1"
            type="text"
            disabled
            :value="
              filteredStocks[0].fullName +
              ' ' +
              filteredStocks[0].quantity +
              ' : ' +
              filteredStocks[0].measuring
            "
            class="form-control"
          />

          <input
            v-else-if="
              Object.keys(filteredStocks).length == 0 &&
              Object.keys(selectedItem) != ''
            "
            type="text"
            disabled
            :value="'Stock insuffisant'"
            class="form-control bg-danger text-white"
          />

          <input
            v-else
            type="text"
            disabled
            :value="' '"
            class="form-control"
          />
        </div>

        <div class="col-xl col-2 my-3">
          <label for="">Quantité</label>
          <input v-model="repairItem.quantity" class="form-control" required />
        </div>

        <div class="col-xl col-2 my-3">
          <label for="">Prix TTC </label>
          <input required v-model="repairItem.priceTTC" class="form-control" />
        </div>

        <div class="col-xl col my-3">
          <label for=""> Remarque </label>
          <input v-model="repairItem.remark" class="form-control" />
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="col-auto">
          <label for=""></label>
          <button class="btn btn-success shadow" type="submit">
            <i class="bi bi-plus-circle"></i> Insérer
          </button>
        </div>

        <div class="col-auto">
          <label for=""></label>
          <button
            type="button"
            class="btn btn-secondary d-flex align-items-center"
            @click="refresh()"
          >
            <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
            <span> Actualiser </span>
          </button>
        </div>
      </div>
    </form> -->
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix TTC</th>
          <th scope="col">Remarque</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(service, index) in repairServices" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="service.service">
              {{ service.service.fullName }}
            </span>
          </td>

          <td class="">
            <span v-if="service.priceTTC != null">
              {{ service.priceTTC }}
            </span>
          </td>

          <td>
            {{ service.remark }}
          </td>

          <td class="d-flex">
            <button @click="remove(service)" class="btn text-danger">
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <!-- <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix HT</th>
          <th scope="col">Prix TTC</th>
          <th scope="col">Quantité</th>
          <th scope="col">Total TTC</th>
          <th scope="col">Stock</th>
          <th scope="col">Remarque</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in repairItems" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>

          <td class="">
            <span v-if="item.priceHT != null">
              {{ item.priceHT }}
            </span>
          </td>

          <td class="">
            <span v-if="item.priceTTC != null">
              {{ item.priceTTC }}
            </span>
          </td>

          <td class="">
            <span v-if="item.quantity != null">
              {{ item.quantity }}
            </span>
            <span v-if="item.item">
              {{ item.item.measuring }}
            </span>
          </td>
          <td class="">
            <span v-if="item.totalPriceTTC != null">
              {{ item.totalPriceTTC }}
            </span>
          </td>
          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }}
            </span>
          </td>
          <td>
            {{ item.remark }}
          </td>

          <td class="d-flex">
            <button @click="remove(item)" class="btn text-danger">
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-0">
          <td></td>

          <td>Total :</td>
          <td class=""></td>
          <td>
            <span
              v-if="totalQuantityItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalQuantityItems }}
            </span>
          </td>
          <td>
            <span
              v-if="totalPriceTTCItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalPriceTTCItems }}
            </span>
            DH
          </td>

          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <br /> -->
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-xl-8 col-12">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives aux articles de la vente (
            Quantité totale : <strong>{{ totalQuantityItems }}</strong> Kg,
            Mantant totale :
            <strong>
              {{ totalPriceTTCItems }}
            </strong>
            DH, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-xl-auto col-12">
        <button
          class="btn btn-primary"
          @click="next()"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      repairItem: {},
      repairService: {},
      section_reference: null,
      item_reference: null,
      service_reference: null,
      itemCode: null,
      accept: false,
      customer_change: true,
      showError: false,
      errorFields: [],
      order_reference: "",
      selectedItem: {},
      selectedService: {},
      // stocks: [ /* your array of stocks */ ]
    };
  },
  computed: {
    ...mapGetters("service", {
      services: "getAll",
    }),

    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("repair", {
      repairItems: "getItems",
      repairServices: "getServices",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
      errors: "getErrors",
    }),

    ...mapGetters("stock", {
      stocks: "getStocks",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
    filteredStocks() {
      if (this.repairItem.quantity < 0)
        return this.stocks.filter(
          (stock) => stock.item_reference === this.selectedItem.reference
        );

      return this.stocks.filter(
        (stock) =>
          stock.item_reference === this.selectedItem.reference &&
          stock.quantity > 0
      );
    },

    filteredItems() {
      if (this.section_reference == null) {
        return this.items;
      }

      return this.items.filter(
        (item) => item.section_reference == this.section_reference
      );
    },

    filteredServices() {
      if (this.section_reference == null) {
        return this.services;
      }

      return this.items.filter(
        (item) => item.section_reference == this.section_reference
      );
    },
  },
  methods: {
    async saveItem(data) {
      await this.$store.dispatch("repair/storeItem", data);
      await (this.repairItem.item_reference = "");
      await (this.repairItem.stock_reference = "");
      await (this.repairItem.quantity = "");
      await (this.repairItem.priceTTC = "");
      await (this.section_reference = null);
      await (this.item_reference = null);
      await (this.selectedItem = {});
      await this.$store.dispatch("stock/getAll");
    },

    async saveService(data) {
      await this.$store.dispatch("repair/storeService", data);
      await (this.repairItem.service_reference = "");
      await (this.repairItem.priceTTC = "");
      await (this.service_reference = null);
      await (this.selectedService = {});
      await this.$store.dispatch("service/getAll");
    },

    async remove(data) {
      let item = data.item;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("repair/destroyItem", data.id);
          }
        },
      });
    },

    async refresh() {
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("service/getAll");
      this.$store.dispatch("repair/getAllItems");
      this.$store.dispatch("repair/getAllServices");
      this.$store.dispatch("customer/getAll");
      this.$store.dispatch("order/getAll");
      this.$store.dispatch("stock/getAll");
    },

    async next() {
      console.log(this.userRole);
      let order_reference = "";
      let customer_reference = this.repairItem.customer_reference;
      if (this.$route.query.order) order_reference = this.$route.query.order;
      if (this.$route.query.customer)
        customer_reference = this.$route.query.customer;

      this.$router.push({
        name: "repairs-create",
        query: {
          customer_reference: customer_reference,
          order_reference: order_reference,
        },
      });
    },

    async onItemChangeSelected() {
      await (this.repairItem = { priceTTC: 0.0, quantity: 1 });

      await (this.selectedItem = this.items.find(
        (p) => p.reference == this.item_reference
      ));

      await (this.repairItem.item_reference = this.selectedItem.reference);

      await console.log(this.item_reference);
      await console.log(this.items);
      // await console.log(this.selectedItem);
      if (this.selectedItem != null) {
        await (this.item_reference = "");
      }
      await console.log("out if", Object.keys(this.filteredStocks).length);
      if (Object.keys(this.filteredStocks).length == 1) {
        await (this.repairItem.stock_reference =
          this.filteredStocks[0].reference);
        await console.log("in if", Object.keys(this.filteredStocks).length);
        await console.log("in 2 if", this.repairItem.stock_reference);
      }
    },

    async onItemCodeChangeSelected() {
      await (this.repairItem = { priceTTC: 0.0, quantity: 1 });

      await (this.selectedItem = this.items.find(
        (p) => p.code == this.itemCode
      ));

      await (this.repairItem.item_reference = this.selectedItem.reference);

      await console.log(this.itemCode);
      await console.log(this.items);
      // await console.log(this.selectedItem);
      if (this.selectedItem != null) {
        await (this.itemCode = "");
      }
      await console.log("out if", Object.keys(this.filteredStocks).length);
      if (Object.keys(this.filteredStocks).length == 1) {
        await (this.repairItem.stock_reference =
          this.filteredStocks[0].reference);
        await console.log("in if", Object.keys(this.filteredStocks).length);
        await console.log("in 2 if", this.repairItem.stock_reference);
      }
    },

    async onServiceChangeSelected(service_reference) {
      await (this.selectedService = this.services.find(
        (p) => p.reference == service_reference
      ));

      await (this.repairService.service_reference =
        this.selectedService.reference);

      await console.log(this.service_reference);
      await console.log(this.services);
      await console.log(this.repairService);
      if (this.selectedService != null) {
        await (this.service_reference = "");
      }
    },
  },
  async created() {
    await this.$store.dispatch("stock/getAll");
    await this.$store.dispatch("section/getAll");
    await this.$store.dispatch("item/getAll");
    await this.$store.dispatch("service/getAll");
    await this.$store.dispatch("repair/getAllItems");
    await this.$store.dispatch("repair/getAllServices");
    await this.$store.dispatch("customer/getAll");
    await this.$store.dispatch("order/getAll");
  },
  watch: {},
  filters: {
    date: (value) => {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>
